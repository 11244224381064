body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-container {
  margin: 0px 5px;
  cursor: pointer; }

.mft-navbar {
  height: 56px; }

.mft-nav-user div {
  padding-left: 10px; }

.mft-view {
  display: -webkit-flex;
  display: flex;
  height: calc(100vh - 56px);
  width: 98vw; }

.mft-login {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-top: 20px; }

.mft-sidebar {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 200px;
  padding: 0px 5px; }
  .mft-sidebar > div {
    margin: 2px 0px; }
  .mft-sidebar .list-group-item {
    cursor: pointer;
    border: 1px solid;
    border-color: #e6e6e6; }

.mft-content {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: calc(100vw - 220px); }
  .mft-content .nav-link {
    padding: 0.25rem 0.75rem; }

.scenario-container {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%; }

.scenario-content {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex; }

.scenario-view {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%;
  -webkit-flex-direction: column;
          flex-direction: column; }

.rv-discrete-color-legend-item {
  padding: 2px 10px; }

.mft-forecast {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 50vh;
  max-height: 50vh; }
  .mft-forecast .nav-tabs {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; }

.mft-forecast-accounts {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%; }

.mft-forecast-accounts-filter {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .mft-forecast-accounts-filter .dropdown {
    padding-right: 5px; }

.mft-forecast-accounts-table {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex; }

.mft-edit {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 300px;
  max-height: 50vh; }
  .mft-edit .nav-tabs {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; }

.mft-compare {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%; }

.mft-compare-chart {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 300px;
  max-height: 40vh; }

.mft-compare-scenarios {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  height: 100%;
  width: 100%;
  min-height: 300px;
  overflow: auto;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  max-width: 100%; }
  .mft-compare-scenarios .scenario-view {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; }

.mft-compare-container {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  display: -webkit-flex;
  display: flex;
  width: 49%;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 0px 2px 2px 2px; }
  .mft-compare-container .mft-compare-header {
    font-size: 16px;
    margin-bottom: 2px;
    padding: 2px 10px;
    background-color: #e6e6e6;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
    border-radius: 3px; }

.mft-forecast-graph {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .mft-forecast-graph .mft-forecast-graph-legend {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto; }
  .mft-forecast-graph .mft-forecast-graph-chart {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width: 100%; }
    .mft-forecast-graph .mft-forecast-graph-chart div {
      -webkit-flex: 1 1 auto;
              flex: 1 1 auto; }

.mft-scenario-form {
  margin: 5px 0px; }

.mft-variable-rules > td {
  padding: 0px; }

.mft-variable-rules .table {
  margin: 0px;
  background-color: #e6e6e6; }

.tab-content {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  width: 100%; }
  .tab-content .active {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    width: 100%; }

.form-group.required .control-label:after {
  content: "*";
  color: red;
  margin-left: 3px; }

.mft-table-header {
  white-space: pre-line !important;
  word-wrap: break-word; }

.ReactTable .rt-thead {
  overflow-y: scroll; }

.ReactTable .rt-thead::-webkit-scrollbar {
  background: transparent; }


