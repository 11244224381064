$light-gray: rgb(230, 230, 230);

.button-container {
  margin: 0px 5px;
  cursor: pointer;
}

.mft-navbar {
  height: 56px;
}

.mft-nav-user {
  div {
    padding-left: 10px;
  }
}

.mft-view {
  display: flex;
  height: calc(100vh - 56px);
  width: 98vw;
}

.mft-login {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.mft-sidebar {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 0px 5px;

  > div {
    margin: 2px 0px;
  }

  .list-group-item {
    cursor: pointer;
    border: 1px solid;
    border-color: $light-gray;
  }
}

.mft-content {
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  width: calc(100vw - 220px);

  .nav-link {
    padding: 0.25rem 0.75rem;
  }
}

.scenario-container {
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  width: 100%;
}

.scenario-content {
  flex: 1 1 auto;
  display: flex;
}

.scenario-view {
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

.rv-discrete-color-legend-item {
  padding: 2px 10px;
}

.mft-forecast {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 50vh;
  max-height: 50vh;

  .nav-tabs {
    flex: 0 0 auto;
  }
}

.mft-forecast-accounts {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mft-forecast-accounts-filter {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;

  .dropdown {
    padding-right: 5px;
  }
}

.mft-forecast-accounts-table {
  flex: 1 1 auto;
  display: flex;
}

.mft-edit {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 300px;
  max-height: 50vh;

  .nav-tabs {
    flex: 0 0 auto;
  }
}

.mft-compare {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.mft-compare-chart {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-height: 300px;
  max-height: 40vh;
}

.mft-compare-scenarios {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  min-height: 300px;
  overflow: auto;
  flex-wrap: nowrap;
  max-width: 100%;

  .scenario-view {
    flex: 0 0 auto;
  }
}

.mft-compare-container {
  flex: 0 0 auto;
  display: flex;
  width: 49%;
  flex-direction: column;
  margin: 0px 2px 2px 2px;
  .mft-compare-header {
    font-size: 16px;
    margin-bottom: 2px;
    padding: 2px 10px;
    background-color: $light-gray;
    position: sticky;
    top: 0;
    z-index: 1020;
    border-radius: 3px;
  }
}

.mft-forecast-graph {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .mft-forecast-graph-legend {
    flex: 0 0 auto;
  }

  .mft-forecast-graph-chart {
    flex: 1 1 auto;
    display: flex;

    div {
      flex: 1 1 auto;
    }
    height: 100%;
    width: 100%;
  }
}

.mft-scenario-form {
  margin: 5px 0px;
}

.mft-variable-rules {
  > td {
    padding: 0px;
  }
  .table {
    margin: 0px;
    background-color: $light-gray;
  }
}

// Tabs

.tab-content {
  flex: 1 1 auto;
  display: flex;
  height: 100%;
  width: 100%;

  .active {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

// Forms

.form-group.required .control-label:after {
  content: "*";
  color: red;
  margin-left: 3px;
}

// Table
.mft-table-header {
  white-space: pre-line !important;
  word-wrap: break-word;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.ReactTable .rt-thead::-webkit-scrollbar {
  background: transparent;
}
